@tailwind base;
@tailwind components;
@tailwind utilities;

body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
		"Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	background: linear-gradient(
		-45deg,
		hsl(14, 82%, 63%),
		#e73c7e,
		#23a6d5,
		#23d5ab
	);
	background-size: 400% 400%;
	background-attachment: scroll;
	/* background-size: cover; */
	background-repeat: no-repeat;
	animation: gradient 15s ease infinite;
	overflow-x: hidden;
}

@keyframes gradient {
	0% {
		background-position: 0% 50%;
	}

	50% {
		background-position: 100% 50%;
	}

	100% {
		background-position: 0% 50%;
	}
}

.drop-in {
	animation: drop-in 1s ease 500ms backwards;
}

.drop-left {
	animation: drop-left 2.5s ease 550ms backwards;
}

.drop-bottom {
	animation: drop-bottom 2.5s ease 550m backwards;
}

@keyframes drop-in {
	from {
		opacity: 0;
		transform: translateY(-100px);
	}

	to {
		opacity: 1;
		transform: translate(0px);
	}
}

@keyframes drop-left {
	from {
		opacity: 0;
		transform: translateX(-100px);
	}

	to {
		opacity: 1;
		transform: translate(0px);
	}
}
@keyframes drop-bottom {
	from {
		opacity: 0;
		transform: translateY(-100px);
	}

	to {
		opacity: 1;
		transform: translate(0px);
	}
}

*::-webkit-scrollbar-track {
	background: black;
	height: 30px;
	margin-top: 10px;
	margin-bottom: 10px;
}

*::-webkit-scrollbar-thumb {
	background-color: white;
	background-size: 12px;
	/* border-radius: 14px; */
	border: 2px solid black;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
		monospace;
}

.card {
	background-color: rgba(
		255,
		255,
		255,
		0.85
	); /* A soft white with slight transparency */
	border-radius: 10px; /* Rounded corners for a modern look */
	box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
	padding: 20px; /* Ample padding for content */
	backdrop-filter: blur(
		10px
	); /* Adds a slight blur effect for a frosted glass appearance */
}
/* 
.profile-image {
	animation: zoomInFadeIn 1.5s ease-out forwards;
	object-fit: cover;
} */

.profile-image {
	transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}

.profile-image:hover {
	transform: scale(1.05);
	box-shadow: 0 0 15px rgba(255, 255, 255, 0.8);
}

@keyframes zoomInFadeIn {
	0% {
		opacity: 0;
		transform: scale(0.9);
	}
	100% {
		opacity: 1;
		transform: scale(1);
	}
}

.typewriter-cursor {
	display: inline-block;
	margin-left: 2px;
	animation: blink 0.7s infinite;
}

@keyframes blink {
	0%,
	100% {
		opacity: 1;
	}
	50% {
		opacity: 0;
	}
}
